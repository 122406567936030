import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { debounceTime, Subject } from 'rxjs';
import { CommonCustomerView } from '../../../models/common-customer-view';
import { CustomerResponse } from '../../../models/customer-response';
import { MigrationExecutionResponse } from '../../../models/migration-customers-response';
import { UsersResponse } from '../../../models/users-response';
import { AdminService } from '../../../services/admin.service';
import { AuthService } from '../../../services/auth.service';
import { SmartImporterService } from '../../../services/smart-importer.service';
import { truncateString } from '../../../utils/truncate';

@Component({
  selector: 'iwt-estatecloud-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomerDetailComponent {
  errorMessage = { show: false, message: '' };

  isLoading = true;
  showForm = true;
  showEditButton = true;
  showDeleteAccountButton = false;
  openDeleteAccountModal = false;
  isDeleteSupportUserJustRunning = false;

  customer: CustomerResponse | undefined;
  users: UsersResponse = { data: [], totalCount: 0 };
  CommonCustomerView = CommonCustomerView;
  currentView = CommonCustomerView.VIEW;
  migrationStatusResponse: MigrationExecutionResponse | undefined;

  private readonly deleteAccountSubject = new Subject<void>();

  constructor(
    private adminService: AdminService,
    private authService: AuthService,
    private readonly smartImporterService: SmartImporterService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.params.subscribe((params) => void this.init(params));
    this.deleteAccountSubject.pipe(debounceTime(1000)).subscribe(() => {
      void this.deleteAccount();
    });
  }

  async init(params: Params) {
    this.isLoading = true;

    await this.loadCustomer(params['id']);
    await this.loadUsers();
    await this.loadMigrationExecutionStatus();

    if (this.customer !== undefined) {
      // set tab title
      window.document.title = `Kunde ${this.customer.customerId} - Admintool`;
    }

    this.showDeleteAccountButton =
      this.customer !== undefined &&
      (await this.authService.isAdmin()) &&
      (this.customer.isDomestic || this.hasNoLoggedInUser());

    if (this.migrationStatusResponse?.executionStatus !== 'SUCCESS')
      this.showDeleteAccountButton = false;

    this.isLoading = false;
  }

  private async loadCustomer(customerId: string) {
    try {
      this.customer = await this.adminService.getCustomer(customerId);
    } catch (error: unknown) {
      this.errorMessage = {
        show: true,
        message: 'Kunde konnte nicht geladen werden!',
      };
      this.showEditButton = false;
      this.showDeleteAccountButton = false;
      this.showForm = false;
    }
  }

  private async loadUsers() {
    if (this.customer === undefined) {
      return;
    }
    try {
      this.users = await this.adminService.getUsers(this.customer.tenantUuid);
    } catch (error: unknown) {
      if (this.customer.edition === 'Standalone') {
        this.errorMessage = {
          show: true,
          message: 'Logins konnten nicht geladen werden!',
        };
      } else {
        this.errorMessage = {
          show: true,
          message: (error as Error).message,
        };
      }
    }
  }

  async loadMigrationExecutionStatus() {
    if (this.customer === undefined) return;
    try {
      this.migrationStatusResponse =
        await this.smartImporterService.getMigrationExecutions(
          this.customer.tenantUuid,
        );
    } catch (error: unknown) {
      this.errorMessage = {
        show: true,
        message: (error as Error).message,
      };
    }
  }

  getBadgeVariant() {
    if (this.migrationStatusResponse === undefined) return 'white';
    switch (this.migrationStatusResponse.executionStatus) {
      case 'STARTED':
        return 'blue';
      case 'SUCCESS':
        return 'green';
      default:
        return 'red';
    }
  }

  back() {
    void this.router.navigateByUrl('/customers');
  }

  showDeleteModal() {
    this.openDeleteAccountModal = true;
  }

  async closeModal(event?: string) {
    this.openDeleteAccountModal = false;
    if (event === 'delete') {
      this.isLoading = true;
      this.deleteAccountSubject.next();
    }
  }

  async deleteAccount() {
    if (this.customer === undefined) {
      this.isLoading = false;
      return;
    }

    try {
      await this.adminService.deleteAccount(this.customer.tenantUuid);
      void this.router.navigateByUrl('/customers');
    } catch (error: unknown) {
      this.errorMessage = {
        show: true,
        message: (error as Error).message,
      };
    }
    this.isLoading = false;
  }

  async deleteSupportUserClick() {
    if (!this.customer?.tenantUuid) return;
    if (this.isDeleteSupportUserJustRunning) return;
    this.isDeleteSupportUserJustRunning = true;

    try {
      await this.smartImporterService.deleteSupportUser(
        this.customer.tenantUuid,
      );
    } catch (error: unknown) {
      this.errorMessage = {
        show: true,
        message: (error as Error).message,
      };
      return;
    }

    try {
      await this.adminService.activateCloudLogin(this.customer.tenantUuid);
    } catch (error: unknown) {
      this.errorMessage = {
        show: true,
        message: (error as Error).message,
      };
    }
  }

  private hasNoLoggedInUser() {
    const loggedInUsers = this.users.data.filter(
      (user) =>
        user.email &&
        user.enabled &&
        user.lastLoginTime !== undefined &&
        user.lastLoginChallanges !== undefined,
    );

    return this.users.totalCount === 0 || loggedInUsers.length === 0;
  }

  truncateString(text: string | null | undefined): string {
    return truncateString(text, 100);
  }

  copyToClipboard(id: number | string | undefined) {
    if (!id) return;
    void navigator.clipboard.writeText(String(id));
  }

  onKeyDown(event: KeyboardEvent) {
    console.log('key down', { key: event.key, isTrusted: event.isTrusted }); // sonar issue Mouse events should have corresponding keyboard events
  }
}
